import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import CustomTooltip from '../ui/CustomTooltip';
import DynamicCardList from '../dynamic/DynamicCardList';
import DynamicChipList from '../dynamic/DynamicChipList';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

const Audiences = ({
  audienceData,
  setAudienceData,
  hasAudienceData,
  isShowAudienceError,
  isShowAudienceSizeError,
  pageHasError,
  setPageHasError,
  surveyName,
  validateSurveyName,
  hasSurveyNameError,
  validateAudience,
  validateAudienceSize,
  segmentData,
  setSegmentData,
  segmentSizeLimit,
  segmentDeleteCallback,
  segmentCharLimit,
  segmentCountLimit,
  hasCustomSplit,
  setHasCustomSplit,
  segmentsWithErrors,
  segmentSuggestions,
  audienceSuggestions
}) => {
  const emptySegment = {
    id: 0,
    audience_id: '',
    segment_name: '',
    segment_size: hasCustomSplit ? null : Math.floor(audienceData[0].audience_size / (segmentData.length + 1)),
    audience_segment_id: '',
    segment_chips: [],
    has_name_error: false,
    has_size_error: false,
    sort_order: 1
  };
  const audienceSizeHelperText = () => {
    let helperText = '';
    if (isShowAudienceSizeError) {
      helperText = 'Please enter a number from 1-1000';
    } else if (hasCustomSplit) {
      helperText = 'Audience Size is ignored while Custom Distribution is selected, please enter segment sizes manually below';
    }
    return helperText;
  };

  return (
    <Grid container>
      <Grid xs={12} md={12} lg={12} >
        <Container className="left-panel">
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
                <TextField
                  id="survey-name"
                  label="Survey Name"
                  data-test-id="survey-name"
                  fullWidth
                  error={hasSurveyNameError}
                  helperText={hasSurveyNameError ? 'Please add a survey name' : 'Survey names should be unique and do not affect results'}
                  name='surveyName'
                  value={surveyName}
                  className={hasSurveyNameError ? 'has-error' : ''}
                  onChange={(e) => validateSurveyName(e.target.value)}
                  />
              </Box>
            </Box>
            <h2>
              Audience
            </h2>
            <p>
            This is a description that encompasses all characteristics that survey respondents should share. It sets a clear hierarchy for organizing data.
            You can be general or specific depending on your objective. For example, "Soda drinkers in America" or "Car enthusiasts in California."
            </p>
            <Box className="textBox" sx={{ mt: 2 }}>
            <Box className="dynamic-card-field">
                <TextField
                    id={`audience-size-input`}
                    className={`audience-size-input`}
                    label="Audience Size"
                    data-test-id={`audience-size-input`}
                    type="number"
                    error={isShowAudienceSizeError}
                    helperText={audienceSizeHelperText()}
                    value={audienceData[0].audience_size}
                    sx={{ width: '175px' }}
                    disabled={hasCustomSplit ? true : false}
                    // onFocus={(e) => {
                    //     let audienceSize = e.target.value;
                    //     audienceSize === 0 ? audienceSize = '' : audienceSize = audienceSize;
                    // }}
                    onChange={(e) => validateAudienceSize(e.target.value)}/>
            </Box>
              <Box className="dynamic-card-field">
              {/* <TextField
                  id="audience"
                  label="Audience"
                  data-test-id="audience"
                  fullWidth
                  error={isShowAudienceError}
                  helperText={isShowAudienceError ? 'Please add an audience up to 1000 characters in length' : ''}
                  value={audienceData[0].audience}
                  className={isShowAudienceError && !hasAudienceData ? 'has-error' : ''}
                  onChange={(e) => validateAudience(e.target.value)}
                  /> */}
                <DynamicChipList
                    listData={audienceData[0].audience_chips}
                    validateAudience={validateAudience}
                    isShowAudienceError={isShowAudienceError}
                    suggestions={audienceSuggestions}
                    setListData={(newChips) => {
                      validateAudience(newChips) && setAudienceData([{...audienceData[0], audience_chips: newChips}]);
                    }}
                    parentLabel={'audience'}
                    label={'audience trait'}
                />
              </Box>
            </Box>
          <h2 style={{ fontSize : '20px' }}>
            Audience Subgroups (Optional)
            {/* <CustomTooltip placement="right"
              title={
                  <React.Fragment>
                  </React.Fragment>
              }>
              <em className="info">i</em>
            </CustomTooltip> */}
          </h2>
          <p>
            Dividing your audience further into subgroups allows for more detailed analysis of your overall
            audience and can help tailor strategies or messages more effectively based on the specific responses of these subgroups. 
          </p>
          {segmentData.length > 0 && (
           <Box>
            <p>
              Subgroup descriptors can be based on things like purchase or use frequency, demographics, psychographics, behaviors, or unique preferences.
              For example, if your audience is “soda drinkers in America” two subgroups you might create could be “drinks soda 3 or fewer times per week” and
              “drinks soda 4 or more times per week.”
            </p>
            <p>
              By default, your audience size will be split evenly amongst your subgroups. To disable this, you can select "Custom Distribution"
              and manually enter your subgroup sizes.
            </p>
            <Switch
              inputProps={{
                'aria-label': 'Custom Distribution',
                'onKeyDown': (e) => {
                  if (e.key === 'Enter') {
                    setHasCustomSplit(!hasCustomSplit);
                  }
              }}}
              onChange={(e) => {
                console.log('switch:', e.target.checked);
                setHasCustomSplit(e.target.checked);
                setAudienceData([{...audienceData[0], custom_distribution: e.target.checked}]);
                e.target.checked && process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Custom Distribution Enabled');
                // if any segments exist in segmentData, set their segment_size to the segment split
                // to refresh them in the UI / inputs so they can be edited from an even-split state
                if (e.target.checked) {
                  const segmentSplit = Math.floor(audienceData[0].audience_size / segmentData.length);
                  const updatedSegments = segmentData.map((segment) => {
                    return {
                      ...segment,
                      segment_size: segmentSplit
                    };
                  });
                  setSegmentData(updatedSegments);
                }
              }}
              checked={hasCustomSplit}/> Custom Distribution {hasCustomSplit ? '(Enabled)' : '(Disabled)'}
           </Box>
          )}
          <Grid item xs={12} md={12} lg={12}>
          <DynamicCardList
            label={'subgroup'}
            emptyItem={emptySegment}
            listData={segmentData}
            setListData={setSegmentData}
            itemDeleteCallback={segmentDeleteCallback}
            segmentSizeLimit={segmentSizeLimit}
            itemsWithErrors={segmentsWithErrors}
            audienceSize={audienceData[0].audience_size}
            hasCustomSplit={hasCustomSplit}
            pageHasError={pageHasError}
            setPageHasError={setPageHasError}
            allowEmpty={true}// segments are optional
            isSortable={true}
            segmentSuggestions={segmentSuggestions}
            segmentCharLimit={segmentCharLimit}
            segmentCountLimit={segmentCountLimit}/>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default Audiences;