import Chart from 'chart.js/auto';

import * as React from 'react';
import { useMemo } from 'react';

const RadarChartComponent = ({ radarData, question }) => {

    React.useEffect(() => {
        console.log('*** in radar chart component useEffect');
        console.log(radarData);
        Chart.defaults.borderColor = 'rgba(0, 0, 0, 0.3)';
        Chart.defaults.color = 'rgba(0, 0, 0)';

        const newChart = new Chart(
            document.getElementById(`radar-chart-${question.question_id}`),
            {
                type: 'radar',
                data: {
                    labels: radarData.labels,
                    datasets: radarData.datasets
                },
                options: {
                    responsive: true,
                    aspectRatio: 2,
                    elements: {
                        line: {
                            borderWidth: 3,
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: 20,
                                boxHeight: 20,
                                font: {
                                    size: 16,
                                    style: 'bold',
                                }
                            }
                        }
                    }
                },
            }
        );

    }, [radarData]);


    return (
        <div className="radar-chart-wrapper">
            <canvas
                id={`radar-chart-${question.question_id}`}
                className="radar-chart"></canvas>
        </div>
    );
}

export default React.memo(RadarChartComponent);