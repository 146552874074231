import Loading from '../ui/Loading';
import BarChartStacked from '../charts/BarChartStacked';
import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, FormControl, FormControlLabel, InputLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useApi } from '../../AuthProvider';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { Label, Print, PrintDisabled } from '@mui/icons-material';
import WordWall from '../charts/WordWall';
import CustomTooltip from '../ui/CustomTooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PieChart from '../charts/PieChart';
import Switch from '@mui/material/Switch';
import Chart from 'chart.js/auto';
import RadarChart from '../charts/RadarChart';
import BarChartComparison from '../charts/BarChartComparison';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsightsIcon from '@mui/icons-material/Insights';


const Result = ({ user }) => {
  const [surveyRunResults, setSurveyRunResults] = useState(null);
  const params = useParams();
  const api = useApi();
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  const [questionNumbersHidden, setQuestionNumbersHidden] = useState(false);
  const navigate = useNavigate();

  const fullColorPaletteCategorical = [
    "rgba(34, 111, 245, 1)",
    "rgba(167, 136, 252, 1)",
    "rgba(53, 202, 152, 1)",
    "rgba(154, 230, 247, 1)",
    "rgba(250, 150, 37, 1)",
    "rgba(226, 31, 96, 1)",
    "rgba(252, 216, 13, 1)",
    "rgba(46, 34, 218, 1)",
    "rgba(136, 244, 20, 1)",
    "rgba(65, 124, 137, 1)",
    "rgba(218, 98, 186, 1)",
    "rgba(129, 0, 193, 1)"
  ];
  const fullColorPaletteCategoricalAlpha = [
    "rgba(34, 111, 245, 0.2)",
    "rgba(167, 136, 252, 0.2)",
    "rgba(53, 202, 152, 0.2)",
    "rgba(154, 230, 247, 0.2)",
    "rgba(250, 150, 37, 0.2)",
    "rgba(226, 31, 96, 0.2)",
    "rgba(252, 216, 13, 0.2)",
    "rgba(46, 34, 218, 0.2)",
    "rgba(136, 244, 20, 0.2)",
    "rgba(65, 124, 137, 0.2)",
    "rgba(218, 98, 186, 0.2)",
    "rgba(129, 0, 193, 0.2)"
  ]

  // Memoize this object to prevent unnecessary re-renders
  const mappedQuestionTypes = useMemo(() => ({
    'short response': 'Open-Ended',
    'long response': 'Open-Ended',
    'single choice': 'Single-Select',
    'multiple choice': 'Multi-Select',
    'numeric rating': 'Numeric Rating'
  }), []);

  useEffect(() => {
    async function fetchData() {
      let surveyRunsResponse = await api.get(`/surveys/runs/${params.surveyRunId}`);
      let surveyStatusResponse = await api.get(`/surveys/runs/${params.surveyRunId}/status`); 

      // sanitize surveyRunResults to support backwards compatibility with old survey results
      surveyRunsResponse.data.questions.forEach(question => {
        question.question_type = question.question_type.toLowerCase();
      });
      setSurveyRunResults(surveyRunsResponse.data);
      setSurveyStatus(surveyStatusResponse.data.status);
      console.log('surveyRunResults:', surveyRunsResponse.data);
    }
    if (surveyRunResults === null && api) {
      fetchData();
    }
  }, [api, params.surveyRunId, surveyRunResults]);

  const downloadSummary = useCallback((surveyRunId, summary) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([summary], {
        type: 'text/markdown',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${surveyRunId}.md`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  const downloadFile = useCallback(async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunResults.name}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }, [api, surveyRunResults]);

  const [personaList, setPersonaList] = useState([]);
  const [audienceStats, setAudienceStats] = useState({});
  useEffect(() => {
    if (surveyRunResults) {
      let tempPersonaList = [];
      // store the first question's ID
      const firstQuestionId = surveyRunResults.questions[0].question_id;
      // filter the answers to just first question
      const filteredAnswers = surveyRunResults.answers.filter(answer => answer.question_id === firstQuestionId);
      // since everyone answers each question, each of these answers should have a unique persona. Add them the the personaList array
      filteredAnswers.forEach(answer => {
        answer.persona && tempPersonaList.push(JSON.parse(answer.persona));
      });
      // update personaList state
      setPersonaList(tempPersonaList);
    }
  }, [surveyRunResults]);

  useEffect(() => {
    console.log('personaList changed:', personaList);
    if (personaList.length !== 0) {
      // calculate the audience stats
      let tempAudienceStats = {};
      // given a persona like the following:
      // {"id":19,"gender":"Male","age":52,"education_level":"Some college, 1 or more years, no degree","income":"39968.07694964565","location":"Texas","job_title":"Construction","years_of_experience":30,"hobbies":[],"mood":"Happy","verbosity":"Low","disposition":"Optimistic","unique_perspectives":"","favorite_number":36,"employment_status":"Employed","marital_status":"Never married","disability":"Without a disability","race":"White alone"}
      // we want to aggregate the following stats across the entire audience:
      // Gender, Age, Education Level, Income, Location, Employment Status, Marital Status, Disability, Race
      // so we need to loop through the personaList and count the number of occurrences of each value for each attribute
      // and store them in the audienceStats object
      const ignoredAttributes = ['id', 'favorite_number', 'unique_perspectives', 'years_of_experience', 'hobbies'];
      personaList.forEach(persona => {
        if (!persona) {
          return;
        }
        // loop through the keys of the persona object
        Object.keys(persona).forEach(key => {
          // if the key is in the ignoredAttributes array, skip it
          if (ignoredAttributes.includes(key)) {
            return;
          }
          // if the key is not in the audienceStats object, add it
          if (!tempAudienceStats[key]) {
            tempAudienceStats[key] = {};
          }
          // if the value is not in the audienceStats object, add it
          if (!tempAudienceStats[key][persona[key]]) {
            tempAudienceStats[key][persona[key]] = 1;
          } else {
            tempAudienceStats[key][persona[key]] += 1;
          }
        });
      });
      console.log('tempAudienceStats:', tempAudienceStats);
      // compute percentages for each attribute
      Object.keys(tempAudienceStats).forEach(key => {
        // get the total number of occurrences for this attribute
        const total = Object.values(tempAudienceStats[key]).reduce((a, b) => a + b, 0);
        // loop through the values of this attribute, calculate the percentage, and save it as a new key
        Object.keys(tempAudienceStats[key]).forEach(value => {
          tempAudienceStats[key][value] = {
            count: tempAudienceStats[key][value],
            percentage: parseFloat(((tempAudienceStats[key][value] / total) * 100).toFixed(2))
          };
        });
      });
      // update the audienceStats state
      setAudienceStats(tempAudienceStats);
    }
  }, [personaList]);

  const QuestionResultDetails = React.memo(({ chartType, question, stackedSeries = [], answers, segments }) => {
    const [numShow, setNumShow] = useState(5);
    const [textShow, setTextShow] = useState('Show more');
    const [isLoadingWords, setIsLoadingWords] = useState(true);
    const ignoredTerms = [
      // awful way to do this
      "i", "me", "my", "mine", "i'm", "you", "your", "yours",
      "he", "him", "his", "she", "her", "hers", "it", "its", "it's", "we", "us", "our", "ours",
      "they", "them", "their", "theirs", "this", "that", "these", "those", "who", "whom", "whose",
      "what", "which", "anyone", "someone", "everyone",
      "nobody", "everybody", "somebody", "each", "either", "neither",
      "in", "inside", "into", "on", "onto", "upon", "at", "by", "for", "from",
      "with", "without", "within", "while", "other", "another",
      "about", "above", "across", "after", "before", "behind", "below", "beneath",
      "between", "among", "beyond", "through", "during", "throughout",
      "under", "underneath", "until", "till", "because", "since", "after", "before",
      "over", "around", "also", "again", "always", "never", "ever",
      "despite", "except", "more", "less", "most", "least",
      "like", "unlike", "have", "has", "had", "having", "just",
      "of", "off", "often", "oh", "sure", "actually", "there", "there's",
      "to", "toward", "sometimes", "always", "never", "many", "few", "much",
      "against", "along", "and", "a", "the", "is", "as", "are", "were", "was", "san", "los",
      "who", "what", "where", "when", "why", "how", "which", "whom", "whose",
      "can", "could", "may", "might", "shall", "should", "will", "would",
      "do", "did", "does", "doing", "done", "be", "but", "or", "nor", "neither", "yet", "so",
    ];

    // Memoize the expensive word frequency calculation for short/long responses
    const { finalList, questionAnswers } = useMemo(() => {
      if (question.question_type === 'short response' || question.question_type === 'long response') {
        const filteredAnswers = answers.filter(answer => answer.question_id === question.question_id);
        let answerTerms = [];
        let startTime = new Date().getTime();
        
        // loop through the answers, and split them into words, then count the frequency of each word.
        filteredAnswers.forEach(answer => {
          // sanitize
          if (answer.answer) {
            // remove punctuation
            let sanitizedAnswer = answer.answer.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
            // remove extra spaces
            sanitizedAnswer = sanitizedAnswer.replace(/\s{2,}/g, ' ');
            // remove leading/trailing spaces
            sanitizedAnswer = sanitizedAnswer.trim();
            // convert to lowercase
            sanitizedAnswer = sanitizedAnswer.toLowerCase();
            
            let words = sanitizedAnswer.split(' ');
            // remove ignored terms
            words = words.filter(word => !ignoredTerms.includes(word));
            words.forEach(word => {
              let term = answerTerms.find(term => term.text === word);
              if (term) {
                term.value += 1;
              } else {
                answerTerms.push({
                  text: word,// some charts need text
                  value: 1,
                  total_responses: filteredAnswers.length,
                  label: word// some charts need label
                });
              }
            });
          }
        });
        
        // find the top 10 most common words
        answerTerms.sort((a, b) => b.value - a.value);
        // slice the array to only include the top 10 most common words
        const topWords = answerTerms.slice(0, 20);
        
        let endTime = new Date().getTime();
        console.log('word wall processing time:', endTime - startTime, 'ms');
        
        return { finalList: topWords, questionAnswers: filteredAnswers };
      }
      return { finalList: [], questionAnswers: [] };
    }, [question.question_id, question.question_type, answers]);
    
    // Memoize the expensive chart data calculation for choice/rating questions
    const { chartLabels, chartStackedSeries, segmentChartData, radarChartData } = useMemo(() => {
      if (question.question_type === 'multiple choice' || question.question_type === 'single choice' || question.question_type === 'numeric rating') {
        const labels = segments.map(segment => segment.segment_chips?.length > 0 ? segment.segment_chips.join(' ') : segment.segment_name);
        const questionAnswers = answers.filter(answer => answer.question_id === question.question_id);
        
        let segmentChartData = [];
        let stackedSeries = [];
        
        segments.forEach(segment => {
          let segmentEntry = {
            label: segment.segment_chips?.length > 0 ? segment.segment_chips.join(' ') : segment.segment_name,
            id: segment.audience_segment_id,
            rawCounts: {},
            percentages: {},
            totalOptions: 0,
            size: segment.segment_size
          };
          
          // set rawCount and percentage placeholders using lowercase values initially
          question.options.forEach(option => {
            segmentEntry.rawCounts[option] = 0;
            segmentEntry.percentages[option] = '0';
          });
          
          segmentChartData.push(segmentEntry);
        });
        
        let splitOptions = [];// array of arrays, each containing obj w/ original option and split chunks

        // sometimes the question options are split on commas when they are returned in answer_array, so we need to
        // look at the questions and find any that contain commas. If so, we need to split that option on commas to generate
        // the same structure as the answer_array (when it splits), and then look for those split chunks in the answer_array
        // and if we find them ALL in the answer_array, we can increment the rawCounts for that option. This is costly but eliminates
        // the need to check for splitting on comma-only and comma-space as well as unsplit options
        if (question.options.some(option => option.includes(','))) {
          question.options.forEach(option => {
            if (option.includes(',')) {
              let entry = {
                original: option,
                split: option.split(',')
              };
              entry.split.forEach((chunk, index) => {
                entry.split[index] = chunk.trim();
              });
              splitOptions.push(entry);
            }
          });
        }
        
        // console.log('splitOptions:', question.question_text, splitOptions);
        
        // Create a deep copy of questionAnswers to avoid mutating the original data
        const questionAnswersCopy = JSON.parse(JSON.stringify(questionAnswers));
        
        questionAnswersCopy.forEach(answer => {
          let segment = segmentChartData.find(segment => segment.id === answer.audience_segment_id);
          
          if (!segment) return; // Skip if segment not found

          if (question.question_type === 'numeric rating') {
            // numeric rating question
            if (segment.rawCounts[answer.numeric_value] === undefined) {
              segment.rawCounts[answer.numeric_value] = 1;
            } else {
              segment.rawCounts[answer.numeric_value] += 1;
            }
            // now increment the totalOptions for this segment
            segment.totalOptions += 1;
          } else {
            answer.answer_array.forEach(option => {
              let lcOptionIndex = question.options.findIndex(qOption => qOption.toLowerCase() === option.toLowerCase());
              let perMissingOptionIndex = question.options.findIndex(qOption => qOption.toLowerCase() === option.toLowerCase() + '.');
              let perFoundInOption = option.endsWith('.');
              let perQuestionIndex = question.options.findIndex(qOption => qOption[qOption.length - 1] === '.');

              if (question.question_type !== 'numeric rating') {
                if (lcOptionIndex > -1) {
                  // direct match found with lowercase comparison
                  option = question.options[lcOptionIndex];
                  segment.rawCounts[option] += 1;
                } else if (perMissingOptionIndex > -1) {
                  // if a direct match is not found, this checks if the response was missing a period at the end of the option
                  option = question.options[perMissingOptionIndex];
                  segment.rawCounts[option] += 1;
                } else if (perFoundInOption) {
                  // response was not missing a period, so check if response is adding a period that is not in the original option
                  question.options.forEach(qOption => {
                    if (option === qOption + '.') {
                      segment.rawCounts[qOption] += 1;
                    }
                  });
                }

                if (splitOptions.length > 0) {
                  // the only remaining variation to check after capitalization and "." removal is to check for a comma-containing
                  // option that has been split into chunks in the answer_array
                  splitOptions.forEach(splitOption => {
                    let optionIsChunk = splitOption.split.includes(option);
                    if (optionIsChunk) {
                      // for each option, check to see if all the chunks included in its split array are found in the answer_array
                      let allChunksFound = splitOption.split.every(chunk => answer.answer_array.includes(chunk));
                      if (allChunksFound) {
                        // indirect match found through split concatenation
                        segment.rawCounts[splitOption.original] += 1;
                        // remove the chunks from the answer_array so they are not counted again
                        splitOption.split.forEach(chunk => {
                          let chunkIndex = answer.answer_array.findIndex(answerChunk => answerChunk === chunk);
                          if (chunkIndex > -1) {
                            answer.answer_array.splice(chunkIndex, 1);
                          }
                        });
                      }
                    }
                  });
                }
              }

              // now increment the totalOptions for this segment
              segment.totalOptions += 1;
            });
          }
        });
        
        console.log('segmentChartData:', segmentChartData);
        
        // calculate the percentages for each option
        segmentChartData.forEach(segment => {
          Object.keys(segment.rawCounts).forEach(option => {
            segment.percentages[option] = ((segment.rawCounts[option] / segment.size) * 100 || 0).toFixed(1);
          });
        });

        let tempOptions = question.options;
        // if the question is a numeric rating question, we need to find the scale
        if (question.question_type === 'numeric rating') {
          tempOptions = [];
          // find the min/max of the responses
          let min = question.options.length > 0 ? question.options[0] : 1;
          let max = question.options.length > 0 ? question.options[1] : 5;
          for (let i = min; i <= max; i++) {
            tempOptions.push(i.toString());
          }
        // calculate the mean / median / mode response value for each segment
        // and update the question obj with the values
        question.means = [];
        question.medians = [];
        question.modes = [];
          segmentChartData.forEach(segment => {
            let total = 0;
            let count = 0;
            let maxCount = 0;
            let modeValue = 0;
            let median = 0;
            let medianArr = [];
            Object.keys(segment.rawCounts).forEach(option => {
              total += parseInt(option) * segment.rawCounts[option];
              count += segment.rawCounts[option];
              if (segment.rawCounts[option] > maxCount) {
                maxCount = segment.rawCounts[option];
                modeValue = option;
              }
              for (let i = 0; i < segment.rawCounts[option]; i++) {
                medianArr.push(parseInt(option));
              }
            });
            question.means.push(segment.label === '' ? [surveyRunResults.audiences[0].audience_chips.join(' '), (total / count).toFixed(2)] : [segment.label, (total / count).toFixed(2)]);
            question.modes.push(segment.label === '' ? [surveyRunResults.audiences[0].audience_chips.join(' '), modeValue] : [segment.label, modeValue]);
            medianArr.sort((a, b) => a - b);
            if (medianArr.length % 2 === 0) {
              median = (medianArr[medianArr.length / 2 - 1] + medianArr[medianArr.length / 2]) / 2;
            } else {
              median = medianArr[Math.floor(medianArr.length / 2)];
            }
            question.medians.push(segment.label === '' ? [surveyRunResults.audiences[0].audience_chips.join(' '), median] : [segment.label, median]);
          });
        }

        const radarData = {
          labels: tempOptions,
          datasets: segmentChartData.map(segment => {
            return {
              label: segment.label.toString(),
              data: tempOptions.map(option => segment.rawCounts[option] || 0),
              fill: true,
              backgroundColor: fullColorPaletteCategoricalAlpha[segmentChartData.indexOf(segment)],
              borderColor: fullColorPaletteCategorical[segmentChartData.indexOf(segment)],
              color: '#000',
              borderWidth: 1
            };
          })
        };
        
        // now we create the stackSeries items for the stacked bar chart
        if (question.question_type === 'numeric rating') {
          // this is a numeric rating question, so we use min/max values for the options
          // but we need to make sure there are no non-parseable values in the answers
          let tempAnswers = questionAnswers.filter(answer => !isNaN(parseInt(answer.answer_array[0])));
          let min = question.options.length > 0 ? question.options[0] : 1;
          let max = question.options.length > 0 ? question.options[1] : 5;
          // creates the labels for a numeric chart
          let choices = [];
          for (let i = min; i <= max; i++) {
            choices.push(i.toString());
          }
          choices.forEach((label) => {
            //built the series entry
            let seriesEntry = {
              label: label,
              data: [],
              value: 0,
              total_responses: 0,
              stack: 'total',
              valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[label] || 0} (${segmentChartData[dataIndex].percentages[label] || 0}%)`
            };
            stackedSeries.push(seriesEntry);
          });
          // now we populate the data for each option in the stackedSeries
          stackedSeries.forEach(series => {
            // append the percentage value for each option in each segment to the series.data array
            segmentChartData.forEach(segment => {
              const percent = segment.percentages[series.label] || 0;
              const count = segment.rawCounts[series.label] || 0;

              series.data.push(percent);
              series.value += parseInt(count);
              series.total_responses += parseInt(segment.size);
            });
          });
        } else if (question.question_type === 'single choice') {
          // this is a single choice question
          question.options.forEach(option => {
            let seriesEntry = {
              label: option,
              data: [],
              value: 0,
              total_responses: 0,
              stack: 'total',
              valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[option] || 0} (${segmentChartData[dataIndex].percentages[option] || 0}%)`
            };
            stackedSeries.push(seriesEntry);
          });
          // now we populate the data for each option in the stackedSeries
          stackedSeries.forEach(series => {
            // append the percentage value for each option in each segment to the series.data array
            segmentChartData.forEach(segment => {
              const percent = segment.percentages[series.label] || 0;
              const count = segment.rawCounts[series.label] || 0;

              series.data.push(percent);
              series.value += parseInt(count);
              series.total_responses += parseInt(segment.size);
            });
          });
        } else {
          ///////// this is a multiple choice question
          // for single choice and numeric rating, the responses always return a single value from each respondent
          // so the percentage value for # of time option was chosen / total options chosen is
          // the same as "percent of answers that include this response". This is **not** true for mult choice,
          // because the same respondent can choose multiple options, so the percentage of answers that include
          // this response is not the same as the percentage of times this response was chosen. THUS we need a different calculation
          // to ensure rawCounts represent number of responses that include this option
          question.options.forEach(option => {
            let seriesEntry = {
              label: option,
              data: [],
              value: 0,
              total_responses: 0,
              valueFormatter: (value, { dataIndex }) => `${segmentChartData[dataIndex].rawCounts[option] || 0} (${segmentChartData[dataIndex].percentages[option] || 0}%)`
            };
            stackedSeries.push(seriesEntry);
          });
          // now we populate the data for each option in the stackedSeries
          stackedSeries.forEach(series => {
            // append the rawCounts for each option in each segment to the series.data array
            segmentChartData.forEach(segment => {
              const percent = Math.floor((segment.rawCounts[series.label] / segment.size) * 100) || 0;
              const count = segment.rawCounts[series.label] || 0;

              series.data.push(percent);
              series.value += parseInt(count);

              series.total_responses += parseInt(segment.size);
            });
          });
        }
        
        console.log('finished building chart, stackedSeries: ', stackedSeries);
        return { chartLabels: labels, chartStackedSeries: stackedSeries, segmentChartData, radarChartData: radarData };
      }
      return { chartLabels: [], chartStackedSeries: [], segmentChartData: [] };
    }, [question, segments, answers]);

    useEffect(() => {
      if (question.question_type === 'short response' || question.question_type === 'long response') {
        setIsLoadingWords(false);
      }
    }, [question.question_type]);

    if (question.question_type === 'short response' || question.question_type === 'long response') {
      return (
        <>
          <div className='short-response-card'>
            <div className="short-response-column">
            {/* if length answers is less than 5 */}
            {
              (questionAnswers.length <= 5)
              &&
              questionAnswers.map((answer, index) => (
                <div className="text-response" key={index}>
                  <p>{answer.answer}</p>
                </div>
              ))
            }
            {/* if length answers is greater than 5 */}
            {
              (questionAnswers.length > 5)
              &&
              <div>
                {questionAnswers.slice(0, numShow).map((answer, index) => (
                  <div className="text-response" key={index}>
                    <p>&quot;{answer.answer}&quot;</p>
                  </div>
                ))}
                <Button
                  className="print-hidden"
                  onClick={() => {
                    if (numShow === 5) {
                      setNumShow(questionAnswers.length);
                      setTextShow('Show less');
                    }
                    else {
                      setNumShow(5);
                      setTextShow('Show more');
                    }
                  }}
                >{textShow}</Button>
              </div>
            }
            </div>
            <div className="short-response-column">
            {chartType === 'pie' ? (
              <>
                <h3 className="pie-chart-title">All Answers ({ finalList[0].total_responses })</h3>
                <PieChart pieData={finalList} questionType={question.question_type}/>;
              </>
            ) : (
              <WordWall isLoading={isLoadingWords} labels={finalList} />
            )}
            </div>
          </div>
        </>
      )
    } else if (question.question_type === 'multiple choice' || question.question_type === 'single choice' || question.question_type === 'numeric rating') {
      if (chartType === 'pie') {
        return (
        <>
          <h3 className="pie-chart-title">All Answers ({ chartStackedSeries[0].total_responses })</h3>
          <PieChart pieData={chartStackedSeries} questionType={question.question_type}/>;
        </>
        );
      } else if (chartType === 'radar') {
        return (
          <RadarChart radarData={radarChartData} question={question} />
        );
      } else {
      return (
        <>
        {/* <PieChart data={chartStackedSeries} /> */}
        <BarChartStacked
          type={question.question_type}
          stackedSeries={chartStackedSeries}
          labels={chartLabels}
        />
        </>
      );
    }
  }
    
    return null; // Default return for other question types
  });

  const QuestionAudienceSegmentResults = React.memo(({ question, questionNo, segments, segment, answers, questionNumbersHidden }) => {
    const questionHidden = useRef(false);
    // Force update without re-rendering parent
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleToggleVisibility = useCallback((e) => {
      e.stopPropagation();
      questionHidden.current = !questionHidden.current;
      forceUpdate(); // Trigger icon update only
    }, [forceUpdate]);

    const defaultChartType = (question_type) => {
      if (question_type === 'single choice' || question_type === 'multiple choice') {
        return 'bar';
      } else if (question_type === 'numeric rating') {
        return 'bar';
      } else {
        return 'word';
      }
    }
    console.log('question:', question);
    const [selectedChartType, setSelectedChartType] = useState(defaultChartType(question.question_type));

    return (
      <Box className={`question-results-details ${questionHidden.current ? 'print-hidden' : ''}`}>
        <Box className="chart-controls">
          <p className="chart-header-question-type">{mappedQuestionTypes[question.question_type]}</p>
          <Box className="chart-control-spacer" sx={{ flexGrow: 5 }} />
          <FormControl className="chart-type-select" variant="standard">
            <Select
              value={selectedChartType}
              className={'print-hidden'}
              onChange={(e) => setSelectedChartType(e.target.value)}
              defaultValue={defaultChartType(question.question_type)}>
                <MenuItem
                  disabled={question.question_type !== 'short response' && question.question_type !== 'long response'}
                  value="word">Word Wall</MenuItem>
                <MenuItem
                  disabled={question.question_type !== 'single choice' && question.question_type !== 'multiple choice' && question.question_type !== 'numeric rating'}
                  value="bar">Bar Chart</MenuItem>
                <MenuItem
                  value="pie">Pie Chart</MenuItem>
                <MenuItem
                  disabled={question.question_type !== 'numeric rating' && question.options.length < 3}
                  value="radar">Radar Chart</MenuItem>
            </Select>
          </FormControl>
          {user.is_admin && (
            <CustomTooltip
              title="Toggle the visibiility of this question in the print / PDF view"
              placement="top">
                {questionHidden.current ? <PrintDisabled className="print-toggle print-hidden" color={'error'} /> : <Print className="print-toggle print-hidden" color={'primary'}/>}
                <Switch
                className={'print-hidden'}
                checked={!questionHidden.current}
                onChange={(e) => {
                  console.log('switch:', e.target.checked);
                  handleToggleVisibility(e);
                }}/>
            </CustomTooltip>
          )}
        </Box>
        <h4 className="chart-header-question-text"><span className={questionNumbersHidden ? 'print-hidden' : ''}>{questionNo}.</span>&nbsp;"{question.question_text}"</h4>
        <QuestionResultDetails chartType={selectedChartType} question={question} segments={segments} answers={answers} />
      </Box>
    );
  });

  const QuestionResult = React.memo(({ audience, questionNo, question, segments, answers, questionNumbersHidden }) => {
    // Memoize the segments transformation
    const processedSegments = useMemo(() => {
      if (segments.length === 0) {
        return [{
          segment_name: audience.audience,
          segment_chips: [audience.audience],
          segment_size: audience.audience_size,
          audience_segment_id: null
        }];
      }
      return segments;
    }, [segments, audience]);

    return (
      <>
        {processedSegments.length > 0 && (
          <QuestionAudienceSegmentResults 
            questionNo={questionNo}
            question={question}
            segments={processedSegments}
            answers={answers}
            questionNumbersHidden={questionNumbersHidden}
          />
        )}
        {processedSegments.length === 0 && (
          <p>No subgroups found</p>
        )}
      </>
    );
  });

  const SurveySummary = React.memo(({ summary }) => {
    // Memoize the summary processing
    const processedSummary = useMemo(() => {
      if (summary === null || summary === undefined || (typeof summary === 'object' && Object.keys(summary).length === 0)) {
        return null;
      } else if (typeof summary === 'object') {
        let summaryBlocks = Object.values(summary);
        return summaryBlocks.filter(value => !value.includes('##') && !value.includes('**'));
      }
      return summary;
    }, [summary]);

    if (processedSummary === null) {
      return (
        <div className='summary-block'>
          <Alert severity="warning">
            No summary found for this survey, please contact Crowdwave support for more information
          </Alert>
          <p></p>
        </div>
      );
    } else if (typeof summary === 'string') {
      // If the summary is a string (the old way), render it as markdown
      return (
        <>
          <Markdown className={'summary-block'}>{summary}</Markdown>
        </>
      );
    // If the summary is an object (the new way), render it as HTML
    } else if (typeof summary === 'object') {
      return (
        <div id="synthesis-div">
          {processedSummary.map((value, index) => (
            <SimpleCard key={index}>
              <div className={'summary-block'} dangerouslySetInnerHTML={{ __html: value }}></div>
            </SimpleCard>
          ))}
        </div>
      );
    // If the summary is neither a string nor an object, render it as is
    } else {
      return summary;
    }
  });

  const QuestionResults = React.memo(({ audience, questions, segments, answers, questionNumbersHidden }) => {
    return (
      <>
        {questions.map((question, index) => (
          <QuestionResult 
            key={index} 
            questionNo={index + 1} 
            audience={audience} 
            question={question} 
            segments={segments} 
            answers={answers}
            questionNumbersHidden={questionNumbersHidden}
          />
        ))}
      </>
    );
  });

  const copySummary = useCallback(() => {
    let summary = surveyRunResults.summary;

    if (typeof summary === 'object') {
      // combine all the summary sections into one string
      summary = Object.values(summary).join('\n\n');
      const regex = /(<([^>]+)>)/gi;
      summary = summary.replace(regex, "");
    } else if (typeof summary === 'string') {
      // strip markdown
      summary = summary.replace(/(#)+ /g, '')// headers
        .replace(/\*/g, '')// bold
        .replace(/- /g, '\n');// lists
    }

    navigator.clipboard.writeText(summary);

    setCopySnackbarOpen(true);
  }, [surveyRunResults]);

  const SimpleCard = React.memo(({ title, content, children }) => {
    // Memoize the card class calculation
    const cardClass = useMemo(() => {
      const headerTags = ["<h1>", "<h2>", "<h3>", "<h4>"];
      const isExpanded = 
        (content && headerTags.some(el => content.includes(el))) || 
        (children?.props?.dangerouslySetInnerHTML?.__html && 
         headerTags.some(el => children.props.dangerouslySetInnerHTML.__html.includes(el)));
      
      return isExpanded ? 'expanded-synthesis-block' : 'simple-synthesis-block';
    }, [content, children]);

    return (
      <Card className={`simple-card ${cardClass}`} xs={12}>
        <CardContent>
          <span className="card-title">{ title?.length > 0 && <h3>{ title }</h3> }</span>
          <span className="card-content">
            { cardClass === 'simple-synthesis-block' && <QueryStatsIcon color="primary" sx={{ position: 'relative', top: '7px', mr: 1, height: 32, width: 32, display: 'inline-block' }}/> }
            { content }
            { children }
          </span>
        </CardContent>
      </Card>
    );
  });

  const [visibleSegments, setVisibleSegments] = useState(5);
  const [visibleQuestions, setVisibleQuestions] = useState(5);

  const toggleVisibleSegments = useCallback(() => {
    setVisibleSegments(prev => 
      prev === 5 ? surveyRunResults.audiences[0].segments.length : 5
    );
  }, [surveyRunResults]);

  const toggleVisibleQuestions = useCallback(() => {
    setVisibleQuestions(prev => 
      prev === 5 ? surveyRunResults.questions.length : 5
    );
  }, [surveyRunResults]);

  const ReportLayout = React.memo(({ setQuestionNumbersHidden, audienceStats }) => {
    // Memoize the total audience size calculation
    const totalAudienceSize = useMemo(() => {
      if (!surveyRunResults) return 0;
      
      if (surveyRunResults.audiences[0].segments.length > 0) {
        return surveyRunResults.audiences[0].segments.reduce(
          (total, segment) => total + segment.segment_size, 0
        );
      } else {
        return surveyRunResults.audiences[0].audience_size;
      }
    }, [surveyRunResults]);
    console.log('surveyRunResults:', surveyRunResults);
    // user that have more than one numeric rating questions should have the ability to select one from the dropdown,
    // then select any other numeric rating questions, and then generate a chart that shows the difference between the ratings
    // across the different questions
    const ratingComparisons = [];// stores list of currently active baseline comparisons
    const numericRatingQuestions = surveyRunResults.questions.filter(question => question.question_type === 'numeric rating');
    const [selectedBaseline, setSelectedBaseline] = useState('');// stores the baseline question
    const [comparisonQuestions, setComparisonQuestions] = useState([]);// stores the Q's being compared to the baselin
    if (!surveyRunResults) return null;

    return (
      <Box className='left-panel results-block design-b' style={{ width: 'auto' }}>
        <Grid container spacing={2}>
          <Grid className="report-controls" item xs={12}>
            <Grid className="report-status" item xs={4}>
              <span className={`${surveyStatus} status`}>{surveyStatus === 'running' ? 'In Progress' : surveyStatus}</span>
            </Grid>
            <Grid className="report-actions btnblock" item xs={8}>
              { surveyStatus === 'complete' &&
                <>
                  <Button
                    variant="contained"
                    color="success"
                    className="print-hidden"
                    startIcon={<Print />}
                    onClick={() => window.print()}>
                    Print Report
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="print-hidden"
                    startIcon={<DownloadIcon />}
                    onClick={() => downloadFile(params.surveyRunId)}>
                    Download Results
                  </Button>
                </>
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h1>{surveyRunResults.name}</h1>
          </Grid>
          <Grid item xs={12} className="report-stats">
            <Grid className="stats-group" item xs={8}>
              <Box className="stats-count">
                <Box className="stat">
                  <p className="stat-value">{totalAudienceSize || 0}</p>
                  <p className="stat-label">Respondents</p>
                </Box>
              </Box>
              <Box className="stats-list">
                <p className="stats-list-title">Audience {surveyRunResults.audiences[0].segments.length > 0 && 'and Subgroups'}</p>
                <p className="stats-list-audience">
                  {surveyRunResults.audiences[0].audience_chips.length > 0 ? (
                    surveyRunResults.audiences[0].audience_chips.map((chip, index) => (
                      <Chip key={index} className="chip audience-name" label={ chip } />
                    ))
                  ) : (
                    <Chip className="chip audience-name" label={ surveyRunResults.audiences[0].audience } />
                  )}
                </p>
                {surveyRunResults.audiences[0].segments.map((segment, index) => (
                  <Box key={index} sx={{ display: index > visibleSegments-1 ? 'none' : 'block'}}>
                    <p className="segment-label">Subgroup {index + 1} ({ segment.segment_size } Respondents)</p>
                    <Stack key={index} direction="row" spacing={1}>
                      {segment.segment_chips.length > 0 && segment.segment_chips.map((chip, index) => (
                        <Chip key={index} className="chip segment-name" label={ chip } />
                      ))}
                      {segment.segment_chips.length === 0 && segment.segment_name && <Chip className="chip segment-name" label={ segment.segment_name } />}
                      <p className="segment-size"></p>
                    </Stack>
                  </Box>
                ))}
                {surveyRunResults.audiences[0].segments.length > 5 &&
                  <Link className="print-hidden" onClick={toggleVisibleSegments}>
                    { visibleSegments === 5 ? 'Show More' : 'Show Less' }
                  </Link>
                }
                {user.is_admin && (
                  <Accordion className="audience-visualizations" disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <InsightsIcon className="audience-visualizations-icon" />
                      <p>Audience Insights</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="audience-visualizations-content">
                        {personaList.length === 0 && (
                          <p>No audience metadata found, please copy and re-run this survey again to generate a new audience with metadata.</p>
                        )}
                        {/* loop through the audienceStats and print the keys, counts, and percentages */}
                        {Object.keys(audienceStats).map((key, index) => (
                          <Box key={index} className="audience-stat">
                            <p className="audience-stat-label">{key}:</p>
                              {Object.keys(audienceStats[key]).map((value, valueIndex) => (
                                <p key={valueIndex} className="audience-stat-value">
                                  {value}: {audienceStats[key][value].count} ({audienceStats[key][value].percentage}%)
                                </p>
                              ))}
                          </Box>
                        ))}
                        {/* <Box className="audience-chart">

                        </Box> */}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            </Grid>
            <Grid className="stats-group" item xs={8}>
              <Box className="stats-count">
                <Box className="stat">
                  <p className="stat-value">{surveyRunResults.questions.length || 0}</p>
                  <p className="stat-label">Questions</p>
                </Box>
              </Box>
              <Box className="stats-list">
                <p className="stats-list-title">Questions</p>
                {surveyRunResults.questions.map((question, index) => (
                  <Box key={index} className="question-list-item" sx={{ display: index > visibleQuestions-1 ? 'none' : 'block'}} >
                    <p className="question-list-type">({mappedQuestionTypes[question.question_type]})</p>
                    <p className="question-list-text">{index+1}. { question.question_text }</p>
                    {question.question_type === 'numeric rating' ? (
                      <>
                        {question.options.length > 0 ? (
                          <p className="question-list-option">Scale: { question.options[0] + ' - ' + question.options[1] }</p>
                        ) : (
                          <p className="question-list-option">Scale: 1 - 5</p>
                        )}
                      </>
                    ) : (
                      question.options?.map((option, index) => (
                        <p key={index} className="question-list-option">{ question.options[index] }</p>
                      ))
                    )}
                  </Box>
                ))}
                {surveyRunResults.questions.length > 5 &&
                  <Link className="print-hidden" onClick={toggleVisibleQuestions}>
                    { visibleQuestions === 5 ? 'Show More' : 'Show Less' }
                  </Link>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <SurveySummary summary={surveyRunResults.summary} />
        <div className='review-block'>
          <div className='result-header'>
            <h1>Survey Results</h1>
            {user.is_admin && (
            <span className="print-hidden">
            Hide Question Numbers in PDF
            <Switch
              className="print-hidden"
              checked={questionNumbersHidden}
              onChange={(e) => {
                console.log('switch:', e.target.checked);
                setQuestionNumbersHidden(e.target.checked);
              }}/>
            </span>
            )}
            <a tabIndex={0} onClick={() => downloadFile(params.surveyRunId)} className='download'>
              <DownloadIcon sx={{ position: 'relative', top: '5px'}} />&nbsp;Download Results
            </a>

          </div>
          <div className='results-row'>
            <div className='results-col'>
              <ul>
                <li>
                  Total responses
                  <h2>{surveyRunResults.total_respondents || 0}</h2>
                </li>
                <li>
                  Total questions
                  <h2>{surveyRunResults.questions.length}</h2>
                </li>
                <li>
                  Audience subgroups
                  <h2>{surveyRunResults.audiences[0].segments.length}</h2>
                </li>
              </ul>
              <h2>Audience</h2>
              <div className="results-audience-summary">
                {surveyRunResults.audiences[0].audience_chips.length > 0 ? (
                  surveyRunResults.audiences[0].audience_chips.map((chip, index) => (
                    <Chip key={index} className="chip audience-name" label={ chip } />
                  ))
                ) : (
                  <h4>{surveyRunResults.audiences[0].audience}</h4>
                )}
              </div>
            </div>
          </div>
          <div className='results-row'>
            <h2>Questions</h2>
            {user.is_admin && numericRatingQuestions.length > 1 && (
              <Box className="rating-comparison">
                <h3>Rating Comparison</h3>
                <Box className="rating-comparison controls">
                <p>Select a Numeric Rating question as a baseline then choose other numeric questions to render the comparison chart.</p>
                <Select
                  className="rating-comparison-select"
                  value={selectedBaseline || ''}
                  onChange={(e) => {
                    setSelectedBaseline(e.target.value);
                  }}
                >
                  {numericRatingQuestions.map((question, index) => (
                    <MenuItem key={index} value={question.question_id}>{question.sort_order}. {question.question_text}</MenuItem>
                  ))}
                </Select>
                {numericRatingQuestions.length > 1 && selectedBaseline !== '' && (
                  numericRatingQuestions.map((question, index) => (
                    question.question_id !== selectedBaseline &&
                      <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={`${question.sort_order}. ${question.question_text.length > 40 ? question.question_text.substring(0, 140) + '...' : question.question_text}`}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setComparisonQuestions([...comparisonQuestions, question.question_id]);
                          } else {
                            setComparisonQuestions(comparisonQuestions.filter(q => q !== question.question_id));
                          }
                        }}
                        />
                  ))
                )}
                </Box>
                {selectedBaseline !== '' && comparisonQuestions.length > 0 && (
                  <Box className="rating-comparison chart">
                    <BarChartComparison
                      baseline={selectedBaseline}
                      comparisonQuestions={comparisonQuestions}
                      questions={surveyRunResults.questions}/>
                  </Box>
                )}
              </Box>
            )}
            <QuestionResults
              audience={surveyRunResults.audiences[0]}
              questions={surveyRunResults.questions}
              segments={surveyRunResults.audiences[0].segments}
              answers={surveyRunResults.answers}
              questionNumbersHidden={questionNumbersHidden}
            />
          </div>
        </div>
      </Box>
    );
  });

  return (
    <div className="dashboard">
      <div className="dashboard-content">
      {surveyRunResults ? (
        <ReportLayout setQuestionNumbersHidden={setQuestionNumbersHidden} audienceStats={audienceStats} />
      ) : (
        <>
        <Loading />
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={copySnackbarOpen}
        onClose={() => setCopySnackbarOpen(false)}
        message="Summary copied to clipboard"
        autoHideDuration={2000}
      />
      </div>
    </div>
  );
}

export default Result;
