import * as React from 'react';
import { useMemo } from 'react';
import { PieChart } from '@mui/x-charts-pro';

const PieChartComponent = ({ pieData, questionType }) => {
    const fullColorPaletteCategorical = [
        '#226ff5',
        '#a788fc',
        '#35CA98',
        '#9AE6F7',
        '#FA9625',
        '#E21F60',
        '#FCD80D',
        '#2E22DA',
        '#88F414',
        '#417C89',
        '#DA62BA',
        '#8100C1',
        '#226ff5',
        '#a788fc',
        '#35CA98',
        '#9AE6F7',
        '#FA9625',
        '#E21F60',
        '#FCD80D',
        '#2E22DA',
        '#88F414',
        '#417C89',
        '#DA62BA',
        '#8100C1',
        '#226ff5',
        '#a788fc',
        '#35CA98',
        '#9AE6F7',
        '#FA9625',
        '#E21F60',
        '#FCD80D',
        '#2E22DA',
        '#88F414',
        '#417C89',
        '#DA62BA',
        '#8100C1'
    ];
    const fullColorPalletteSequential = [
        '#CCF6FF',
        '#BCEBF8',
        '#ACE0F1',
        '#9DD5EA',
        '#8DCAE4',
        '#7EBEDD',
        '#6EB2D7',
        '#5EA5D0',
        '#4F98CA',
        '#3F8AC3',
        '#2E7BBC',
        '#1C6BB4',
        '#085AAD',
        '#0047A5',
        '#002F9F',
        '#0B009E'
    ];
    const calcLegendHeight = useMemo(() => {
        let legendHeight = 0;// eventual height of the legend used for chart's margin.top
        let legendWidth = 0;// width of all the options characters horizontally, plus the legend "squares" +/- margins
        let seriesCount = pieData.length;// number of options
        let totalChars = 0;// total number of characters in all the options
        pieData.forEach((series) => {
            totalChars += series.label.length;
        });
        // legend cubes are 20px wide + 5px margin either side, text is 16px wide, add 10% for good measure
        legendWidth = ((seriesCount * 30) + (totalChars * 16));
        // get the current viewport width and knock it down to the 80%-ish we have to play around with
        let viewportWidth = window.innerWidth * .8;
        // short response Q's share their card with the responses
        questionType === 'short response' || questionType === 'long response' ? viewportWidth = viewportWidth / 2 : viewportWidth = viewportWidth;
        // divide the legendWidth by the viewportWidth to get the number of lines and multiply by line height (roughly)
        legendHeight = Math.ceil(legendWidth / viewportWidth) * 35;

        return legendHeight < 50 ? 50 : legendHeight;
    }, [pieData]);
    const calculatedColorPalette = useMemo(() => {
        let calculatedPalette = [];
        let options = pieData;
        if (questionType === 'numeric rating') {
          calculatedPalette = fullColorPalletteSequential;
          // if the list of labels is less than the full color palette, try to split the palette
          // evenly across the labels to increase contrast between label colors
          if (options.length < fullColorPalletteSequential.length) {
            let paletteRatio = Math.floor(fullColorPalletteSequential.length / options.length);
            calculatedPalette = [];
            for (let i = 0; i < fullColorPalletteSequential.length; i++) {
              if (i % paletteRatio === 0) {
                calculatedPalette.push(fullColorPalletteSequential[i]);
              }
            }
          }
        } else {
          calculatedPalette = fullColorPaletteCategorical;
        }
        return calculatedPalette;
      }, [pieData, questionType, fullColorPaletteCategorical, fullColorPalletteSequential]);
    const accumulatedResponses = pieData.reduce((acc, curr) => acc + curr.value, 0);
    return (
        <PieChart
            className="pie-chart"
            colors={calculatedColorPalette}
            series={[
                {
                    // arcLabel: (item) => `${item.label}`,
                    // arcLabelMinAngle: 10,
                    // arcLabelRadius: '50%',
                    data: pieData,// array of objects that have a label and value property
                    valueFormatter: (item) => {
                        if (questionType === 'short response' || questionType === 'long response') {
                            return `${item.value} Instances (${((item.value / accumulatedResponses) * 100).toFixed(1)}%)`
                        } else {
                            return `${item.value} (${((item.value / item.total_responses) * 100).toFixed(1)}%)`
                        }
                    },
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 0, additionalRadius: -30, color: 'gray' },
                },
            ]}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 10,
                },
            }}
            margin={{ top: calcLegendHeight, right: 0, bottom: 0, left: 0 }}
            height={400}
        />
    );
}

export default React.memo(PieChartComponent);