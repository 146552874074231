import { BarChartPro } from '@mui/x-charts-pro';
import * as React from 'react';
import { useMemo } from 'react';
import { Box } from '@mui/material';

const BarChartComparison = ({ baseline, comparisonQuestions, questions }) => {
  
  const fullColorPaletteCategorical = [
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#9AE6F7',
    '#FA9625',
    '#E21F60',
    '#FCD80D',
    '#2E22DA',
    '#88F414',
    '#417C89',
    '#DA62BA',
    '#8100C1',
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#9AE6F7',
    '#FA9625',
    '#E21F60',
    '#FCD80D',
    '#2E22DA',
    '#88F414',
    '#417C89',
    '#DA62BA',
    '#8100C1',
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#9AE6F7',
    '#FA9625',
    '#E21F60',
    '#FCD80D',
    '#2E22DA',
    '#88F414',
    '#417C89',
    '#DA62BA',
    '#8100C1'
  ];
  const fullColorPalletteSequential = [
    '#CCF6FF',
    '#BCEBF8',
    '#ACE0F1',
    '#9DD5EA',
    '#8DCAE4',
    '#7EBEDD',
    '#6EB2D7',
    '#5EA5D0',
    '#4F98CA',
    '#3F8AC3',
    '#2E7BBC',
    '#1C6BB4',
    '#085AAD',
    '#0047A5',
    '#002F9F',
    '#0B009E'
  ];
  // const calcLegendHeight = useMemo(() => {
    //     let legendHeight = 0;// eventual height of the legend used for chart's margin.top
  //     let legendWidth = 0;// width of all the options characters horizontally, plus the legend "squares" +/- margins
  //     let seriesCount = pieData.length;// number of options
  //     let totalChars = 0;// total number of characters in all the options
  //     pieData.forEach((series) => {
    //         totalChars += series.label.length;
  //     });
  //     // legend cubes are 20px wide + 5px margin either side, text is 16px wide, add 10% for good measure
  //     legendWidth = ((seriesCount * 30) + (totalChars * 16));
  //     // get the current viewport width and knock it down to the 80%-ish we have to play around with
  //     let viewportWidth = window.innerWidth * .8;
  //     // short response Q's share their card with the responses
  //     questionType === 'short response' || questionType === 'long response' ? viewportWidth = viewportWidth / 2 : viewportWidth = viewportWidth;
  //     // divide the legendWidth by the viewportWidth to get the number of lines and multiply by line height (roughly)
  //     legendHeight = Math.ceil(legendWidth / viewportWidth) * 35;
  
  //     return legendHeight < 50 ? 50 : legendHeight;
  // }, [pieData]);
  // const calculatedColorPalette = useMemo(() => {
    //     let calculatedPalette = [];
  //     let options = pieData;
  //     if (questionType === 'numeric rating') {
  //       calculatedPalette = fullColorPalletteSequential;
  //       // if the list of labels is less than the full color palette, try to split the palette
  //       // evenly across the labels to increase contrast between label colors
  //       if (options.length < fullColorPalletteSequential.length) {
  //         let paletteRatio = Math.floor(fullColorPalletteSequential.length / options.length);
  //         calculatedPalette = [];
  //         for (let i = 0; i < fullColorPalletteSequential.length; i++) {
  //           if (i % paletteRatio === 0) {
  //             calculatedPalette.push(fullColorPalletteSequential[i]);
  //           }
  //         }
  //       }
  //     } else {
  //       calculatedPalette = fullColorPaletteCategorical;
  //     }
  //     return calculatedPalette;
  //   }, [pieData, questionType, fullColorPaletteCategorical, fullColorPalletteSequential]);
  const chartBaseline = questions.filter((question) => question.question_id === baseline);
  const chartQuestions = questions.filter((question) => comparisonQuestions.includes(question.question_id));
  chartQuestions.unshift(chartBaseline[0]);
  // Get question labels
  const chartLabels = chartQuestions.map((question) => chartQuestions.indexOf(question) === 0 ? 'Baseline' : `Question ${question.sort_order}`);

  // Get number of segments from first question
  const numSegments = chartQuestions[0].means.length;

  const chartBaselineValue = chartBaseline[0].means[0][1] || 0;
  // save the baseline values for each series in an object with their id as the key
  const baselineValues = {};
  chartQuestions[0].means.forEach((segment) => {
    baselineValues[segment[0]] = segment[1];
  });
  // Create series for each segment
  const chartSeries = useMemo(() => {
    const series = [];

    // For each segment
    for (let segmentIndex = 0; segmentIndex < numSegments; segmentIndex++) {
      // Get segment name from first question's means array
      const segmentName = chartQuestions[0].means[segmentIndex][0];

      // Create data array with means from each question for this segment
      const data = chartQuestions.map(question =>
        parseFloat(question.means[segmentIndex][1])
      );

      series.push({
        data,
        label: segmentName,
        type: 'bar',
        id: segmentName,
        color: fullColorPaletteCategorical[segmentIndex],
      });
    }

    return series;
  }, [chartQuestions, numSegments, fullColorPaletteCategorical]);
  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <BarChartPro
        xAxis={[{
          scaleType: 'band',
          data: chartLabels,
          tickLabelStyle: {
            angle: 0,
            textAnchor: 'middle',
          }
        }]}
        grid={{vertical:true}}
        yAxis={[{
          label: 'Mean Rating'
        }]}
        series={chartSeries.map((series) => ({
            ...series,
            // add a formatter to show the raw value and the difference from the baseline
            valueFormatter: (v) => {
              const rawVal = v;
              const difference = rawVal - baselineValues[series.id];
              return `${rawVal.toFixed(2)} (${difference > 0 ? '+' : ''}${difference.toFixed(2)})`;
            }
          }))}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            padding: 8,
          },
        }}
        barLabel={(item, context) => {
          let rawVal = item.value;
          let formattedVal = '';
          let formattedDiff = '';
          let difference = rawVal - baselineValues[item.seriesId];
          if (rawVal !== null) {
            formattedVal = `${rawVal.toFixed(2)}`;
            formattedDiff = `(${difference > 0 ? '+' : ''} ${difference.toFixed(2)})`;
          }
          return context.bar.height < 60 ? null : <tspan><tspan y={0}>{formattedVal}</tspan><tspan x={-2} y={20}>{formattedDiff}</tspan></tspan>;
        }}
        height={500}
      />
    </Box>
  );
}

export default React.memo(BarChartComparison);
