import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import close from '../../assets/close.svg';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Upload from '@mui/icons-material/Upload';
import Download from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/icons-material/Link';
import EditNote from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import upload from '../../assets/upload-icon.svg';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Icon, IconButton, TextField } from '@mui/material';
import templateDoc from '../../assets/documents/Crowdwave Survey Questions Upload Template.xlsx';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DynamicCardList from '../dynamic/DynamicCardList';
import Loading from '../ui/Loading';
import { useApi } from '../../AuthProvider.js';

const Questions = ({
  questionsData,
  setQuestionsData,
  handleUpload,
  validateQuestion,
  questionDeleteCallback,
  questionsWithErrors,
  questionCharLimit,
  questionCountLimit,
  optionCharLimit,
  optionCountLimit,
  surveyId,
  user
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const api = useApi();
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const uploadInfoRows = [
    { question_text: 'Do you enjoy cooking?', question_type: 'Open-Ended', sort_order: 1, options: '' },
    { question_text: 'How would you describe your diet?', question_type: 'Single-Select', sort_order: 4, options: 'Vegan,Dietary Restrictions,Allergen Restrictions,No Restrictions' },
    { question_text: 'What types of food do you buy?', question_type: 'Multi-Select', sort_order: 2, options: 'Fresh,Frozen,Organic,Local,Imported' },
    { question_text: 'Rate your diet from 1 (unhealthy) to 5 (very healthy)', question_type: 'Numeric Rating', sort_order: 3, options: '' },
  ];

  const emptyQuestion = {
    id: 0,
    survey_id: '',
    question_id: '',
    question_text: '',
    question_type: 'short response',
    options: [],
    raw_options: '',
    sort_order: 1,
    has_text_error: false,
    has_options_error: false,
    has_count_error: false
  };

  console.log('****** in questions, surveyId:', surveyId);
  // media upload
  let sampleObj = {
    id: 0,// generated
    name: '',// user input
    type: '',// file, link, text
    content: '',// filepath, url, or text block
    // "document_id": "string",
    // "survey_id": "string",
    // "document_s3_path": "string",
    // "created_at": "2025-03-15T04:35:28.312Z",
    // "created_by": "string"
  }
  const acceptedFileTypes = ".mp4, .avi, .mp3, .jpg, .jpeg, .png, .pdf, .doc, .docx, .csv, .xlsx";
  const listedFileTypes = acceptedFileTypes.replaceAll('.', '');
  const [attachedMedia, setAttachedMedia] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAttachingMedia, setIsAttachingMedia] = useState(false);
  const [isSavingMedia, setIsSavingMedia] = useState(false);
  const [mediaType, setMediaType] = useState('');
  const [mediaName, setMediaName] = useState('');
  const [mediaContent, setMediaContent] = useState('');
  const [mediaBinary, setMediaBinary] = useState(null);
  const [mediaExtension, setMediaExtension] = useState('');
  const [hasMediaError, setHasMediaError] = useState(false);
  const openAttachUI = (type) => {
    // opens the media / attachment editing interface
    console.log('Creating attachment of type:', type);
    setIsAttachingMedia(true);
    setMediaType(type);
  }
  const getMediaList = async () => {
    // get the list of media attachments for this survey
    console.log('Getting media list for survey:', surveyId);
    try {
      const response = await api.get(`/documents/${surveyId}`);
      //setAttachedMedia(response.data);
      if (response && response.status === 200) {
        console.log('getting media:', response);
        setAttachedMedia(response.data.documents);
      }
      return response;
    } catch (error) {
      console.error('Error fetching media:', error);
      return;
    }
  }
  const determineFileExtension = (mediaType) => {
    // determines the extension we want to use when saving to s3
    if (mediaType === 'file') {
      return mediaExtension;
    } else if (mediaType === 'link') {
      return '.txt';
    } else {
      return '.json';
    }
  }
  const handleMediaSave = async () => {
    setIsSavingMedia(true);
    let blob = null;
    // validate the inputs
    if (mediaName === '' || mediaContent === '') {
      setHasMediaError(true);
      return;
    } else {
      // save it to state
      setIsSavingMedia(false);
      setHasMediaError(false);
      console.log('Save Media:', mediaType, mediaName, mediaContent);
      const newMedia = {
        context_name: mediaName,
        file_extension: mediaType == 'file' ? mediaExtension : mediaType,
        content: mediaContent,
        binary: mediaBinary,
        user_uploaded: true,
        document_id: Math.floor(Math.random() * 1000)
      };
      setAttachedMedia([...attachedMedia, newMedia]);
      setIsAttachingMedia(false);
      // depending on the type, we either need to pass the binary directly, or create one to pass
      if (mediaType === 'file') {
        console.log('its a file, selectedFile:', selectedFile);
        setMediaBinary(selectedFile);
      } else {
        let tempJson = {
          type: mediaType === 'text' ? 'json' : mediaType,
          name: mediaName,
          content: mediaContent,
        }
        // create a file object from the tempJson
        blob = new Blob([JSON.stringify(tempJson)], { type: 'application/json' });
        blob = new File([blob], mediaName + determineFileExtension(), { type: 'application/json' });
        setMediaBinary(blob);
      }
      // try to save it to S3
      try {
        console.log('about to post');
        console.log('media:', mediaContent);
        console.log('surveyId:', surveyId);
        console.log('mediaName:', mediaName);
        console.log('mediaBinary:', mediaBinary);
        console.log('blob:', blob);

        const response = await api.post('/documents/', {
          'document': mediaType === 'file' ? mediaBinary : blob,
          'survey_id': surveyId,
          'context_name': mediaName,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } });
        getMediaList();
        return response;
      } catch (error) {
        console.error('Error saving media:', error);
        setIsSavingMedia(false);
        return;
      }
    }
  }

  const handleMediaUpload = (e) => {
    // handles raw file upload in between create and save of media
    if (e.target.files.length === 0) {
      console.log('Please select a file to upload');
      return;
    } else {
      setSelectedFile(e.target.files[0]);
      setMediaBinary(e.target.files[0]);
      setMediaContent(e.target.files[0].name);
      setMediaExtension(e.target.files[0].name.split('.').pop());
      console.log('Media Upload:', e.target.files[0]);
    }
  }

  const handleMediaDelete = async (id) => {
    // delete the media from the list
    console.log('Deleting media:', id);
    // if the media id is not a uuid, then we just need to delete it from state
    if (!id.indexOf('-') > 0) {
      setAttachedMedia(attachedMedia.filter(media => media.id !== id));
      return;
    } else {
      try {
        const response = await api.delete(`/documents/${id}`);
        if (response && response.status === 200) {
          console.log('deleted media:', response);
          setAttachedMedia(attachedMedia.filter(media => media.id !== id));
          getMediaList();
        }
        return response;
      } catch (error) {
        console.error('Error deleting media:', error);
        return;
      }
    }
  }

  const handleMediaDownload = async (id) => {
    // download the media from the list
    console.log('Downloading media:', id);
    if (id.indexOf('-') > 0) {
      try {
        const response = await api.get(`/documents/download/${id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link
          .setAttribute('href', url);
        link.setAttribute('download', id);
        document.body.appendChild(link);
        link.click();
        return response;
      } catch (error) {
        console.error('Error downloading media:', error);
        return;
      }
    } else {
      // it's only saved in state, so no downloading :(
      console.log('media not ready for download yet');
    }
  }

  const mediaContentHelperText = () => {
    if (mediaType === 'text' && !hasMediaError && mediaContent.length < 1000) {
      return `You have ${1000 - mediaContent.length} characters remaining.`;
    } else if (mediaType === 'text' && hasMediaError) {
      return `Please enter a between 0 and 1000 characters.`;
    } else {
      return 'Character limit reached.';
    }
  }

  useEffect(() => {
    // when opening or closing the media attachment interface, reset the error state and form info
    setHasMediaError(false);
    setMediaName('');
    setMediaContent('');
    setSelectedFile(null);
  }, [isAttachingMedia]);

  useEffect(() => {
    // get the list of media attachments for this survey
    surveyId !== '' && surveyId !== undefined && getMediaList();
  }, [surveyId]);

  useEffect(() => {
    console.log('attached media changed:', attachedMedia);
  }, [attachedMedia]);

  return (
  <Grid container>
    <Grid xs={12} md={12} lg={12} >
      <Container className="left-panel">
        <h2>Questions</h2>
        <p>Create your questions manually, or use our bulk upload process to import an XLSX of your existing questions.</p>
        <Grid container spacing={2}>
          <Grid xs={12} md={12} lg={5} style={{ display: isActive ? 'none' : 'block' }}>
            <Button
              onClick={handleToggle}
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              xs={12} md={12} lg={4}>
              Bulk Upload Questions
            </Button>
          </Grid>
          <Grid style={{ display: isActive ? 'block' : 'none' }} xs={6} md={6} lg={7}>
            <p>
              Upload your own document to the right, or download our <a href={templateDoc}>pre-built XLSX template</a> to ensure compatibility.
              The required format is shown below:
            </p>
          </Grid>
          <Grid className='upload-block' style={{ display: isActive ? 'flex' : 'none' }} xs={12} md={12} lg={5}>
            <em className='close-icon' onClick={handleToggle}> <img src={close} alt="upload" /></em>
            <Button component="label" role={undefined} variant="contained" data-test-id="upload-questions-btn">
              <em><img src={upload} alt="upload" /></em> Click to Upload XLSX
              <VisuallyHiddenInput type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} />
            </Button>
          </Grid>
          <Grid xs={12} md={12} lg={12} className="upload-block-info">
            <TableContainer className="upload-info-text" component={Paper} style={{ display: isActive ? 'block' : 'none' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: '2px solid #999' }}>
                    <TableCell>Question Text</TableCell>
                    <TableCell align="center">Question Type</TableCell>
                    <TableCell align="center">Sort Order</TableCell>
                    <TableCell align="center">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadInfoRows.map((row) => (
                    <TableRow
                      key={row.sort_order}
                    >
                      <TableCell>{row.question_text}</TableCell>
                      <TableCell align="center">{row.question_type}</TableCell>
                      <TableCell align="center">{row.sort_order}</TableCell>
                      <TableCell align="center">{row.options}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {user.is_admin && (
          <>
            <h3>Media Upload (optional)</h3>
            <p>
              If you have additional media that you want respondents to consider when answering your questions,
              you can attach it here.<br />Supported media types include images, videos, audio, documents, and custom text.
            </p>
            {!isAttachingMedia && (
            <Grid container spacing={2} className="media-controls">
              <Grid xs={12} md={3} lg={3}>
                <Button
                  variant="outlined"
                  id="attach-media-file"
                  startIcon={<Upload />}
                  xs={12} md={3} lg={3}
                  onClick={() => openAttachUI('file')}>
                  Upload File
                </Button>
                <p className="btn-help-text"><strong>Supported file types:</strong> {listedFileTypes}</p>
              </Grid>
              <Grid xs={12} md={3} lg={3}>
                <Button
                  variant="outlined"
                  id="attach-media-link"
                  startIcon={<Link />}
                  onClick={() => openAttachUI('link')}
                  xs={12} md={12} lg={4}>
                  Paste a Link
                </Button>
                <p className="btn-help-text"><strong>Supported hosts:</strong> YouTube, Imgur</p>
              </Grid>
              <Grid xs={12} md={4} lg={4}>
                <Button
                  variant="outlined"
                  id="attach-media-text"
                  startIcon={<EditNote />}
                  onClick={() => openAttachUI('text')}
                  xs={12} md={3} lg={3}>
                  Enter Custom Text
                </Button>
                <p className="btn-help-text"><strong>Note:</strong> Custom formatting is not supported</p>
              </Grid>
            </Grid>
            )}
            {isAttachingMedia && (
            <Grid container spacing={2} className="media-attach-block">
              <Grid className="media-attach-controls" xs={12} md={12} lg={12}>
                <h3>Attach {mediaType.charAt(0).toUpperCase() + mediaType.slice(1)}</h3>
                <IconButton className="close-icon" onClick={() => setIsAttachingMedia(false)}><CloseIcon /></IconButton>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <p>Give this media a unique name to reference in your survey questions. Respondents will automatically consider this media when answering questions, unless instructed to ignore it.</p>
                <TextField
                      id={`attachment-name-input`}
                      label="Attachment Name"
                      sx={{ minWidth: '70%' }}
                      error={hasMediaError}
                      value={mediaName}
                      helperText={hasMediaError ? `Please enter a valid name for this media` : `Names do not affect results and should be descriptive, e.g "Q4 Product Advertisement v1.2".`}
                      onChange={(e) => {
                        setMediaName(e.target.value);
                      }}
                      />
              </Grid>
              {mediaType === 'file' && (
                <Grid xs={12} md={12} lg={12}>
                  <p>Select a file from your computer to upload</p>
                  {/* <Button component="label" role={undefined} variant="contained" data-test-id="upload-questions-btn">
                  <em><img src={upload} alt="upload" /></em> Click to Upload XLSX
                  <VisuallyHiddenInput type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} />
                </Button> */}
                  <Button
                  component="label"
                  variant="outlined"
                  role={undefined}
                  id="attach-media-text"
                  startIcon={<Upload />}
                  xs={12} md={3} lg={3}>
                    {selectedFile ? selectedFile.name : 'Select File'}
                    <VisuallyHiddenInput type="file" accept={acceptedFileTypes} onChange={(e) => handleMediaUpload(e)} />
                  </Button>
                </Grid>
              )}
              {mediaType === 'link' && (
                <Grid xs={12} md={12} lg={12}>
                  <TextField
                      id={`custom-link-input`}
                      label="Youtube / Imgur Link"
                      error={hasMediaError}
                      fullWidth
                      value={mediaContent}
                      onChange={(e) => {
                        setMediaContent(e.target.value);
                      }}/>
                </Grid>
              )}
              {mediaType === 'text' && (
                <Grid xs={12} md={12} lg={12}>
                  <TextField
                      id={`custom-text-input`}
                      label="Custom Text"
                      error={hasMediaError}
                      fullWidth
                      multiline
                      rows={4}
                      value={mediaContent}
                      // value={item.question_text}
                      // helperText={mediaContentHelperText()}
                      // sx={{ flexGrow: 2 }}
                      onChange={(e) => {
                        // e.target.value.length <= 1000 && setMediaContent(e.target.value);
                        setMediaContent(e.target.value);
                      }}/>
                </Grid>
              )}
              <Grid xs={12} md={12} lg={12}>
                <Button
                  variant="outlined"
                  id="save-media"
                  startIcon={<SaveIcon />}
                  onClick={() => handleMediaSave()}
                  xs={12} md={3} lg={3}
                  color={'success'}>
                  Save Media
                </Button>
                <p className="save-media-help-text"><InfoOutlinedIcon />&nbsp;You cannot edit media after saving</p>
              </Grid>
            </Grid>
            )}
            {attachedMedia.length > 0 && (
            <Grid container spacing={2} className="media-attached-block">
              <Grid xs={12} md={12} lg={12}>
                <h3>Attached Media</h3>
                {isSavingMedia && <Loading />}
              </Grid>
              {attachedMedia.map((media, index) => (
                <Grid key={index} xs={12} md={12} lg={12}>
                  <p>{index+1}.&nbsp;
                    <strong>{media.context_name}</strong>&nbsp;
                    ({media.file_extension === '' ? 'json' : media.file_extension.replaceAll('.', '').toUpperCase()})
                  </p>
                  <IconButton
                    color="primary"
                    className="media-download-icon"
                    onClick={() => {
                      console.log('download media:', media);
                      handleMediaDownload(media.document_id);
                      }}>
                    <Download />
                  </IconButton>
                  <IconButton
                    color="error"
                    className="media-delete-icon"
                    onClick={() => {
                      console.log('delete media:', media);
                      handleMediaDelete(media.document_id);
                      }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            )}
          </>
        )}
        <div>
          <Grid item xs={12} md={12} lg={12}>
          <DynamicCardList
            label={'question'}
            listData={questionsData}
            setListData={setQuestionsData}
            emptyItem={emptyQuestion}
            itemDeleteCallback={questionDeleteCallback}
            itemsWithErrors={questionsWithErrors}
            questionCharLimit={questionCharLimit}
            questionCountLimit={questionCountLimit}
            optionCharLimit={optionCharLimit}
            optionCountLimit={optionCountLimit}
            isSortable={true}/>
          </Grid>
        </div>
      </Container>
    </Grid>
  </Grid>
)}

export default Questions;