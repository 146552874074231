import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../../AuthProvider';

const WhatsNewModal = ({isWhatsNewModalOpen = false, setIsWhatsNewModalOpen, user }) => {
    const navigate = useNavigate();
    const api = useApi();

    React.useEffect(() => {
        if (window.location.pathname === '/' && user !== null && user !== undefined && user?.seen_latest_features === false) {
            //TODO this doesn't seem efficient
            setIsWhatsNewModalOpen(true);
        }
    }, [window.location.pathname, user]);

    return (
        <React.Fragment>
            <Dialog
                open={isWhatsNewModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{maxWidth:'900px', margin:'auto'}}
            >
                <DialogTitle id="alert-dialog-title">
                    <h3>{"Recent Updates"}</h3>
                </DialogTitle>
                <DialogContent className="whats-new-content">
                    <section>
                        <h3>Feb 28, 2025</h3>
                        <ul>
                            <li>
                                <strong>Improved Clarity - Audience “Segments” are now “Audience Subgroups”:</strong>&nbsp;We've updated the terminology in our survey tool to make audience segmentation clearer! What were previously called “Segments" are now labeled “Audience Subgroups." This change reinforces that subgroups are part of your overall audience, helping you better organize and analyze responses from different segments. No functionality has changed—just a clearer way to define and refine your AI-generated audience.
                            </li>
                            <li>
                                <strong>Question Numbering is Now Available on Details & Results Pages:</strong>&nbsp;To improve clarity and consistency, question numbers are now displayed on the Details and Results pages, in addition to the question creation page. This update makes it easier to reference specific questions throughout your survey workflow, ensuring a smoother experience when reviewing and analyzing responses.
                            </li>
                        </ul>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        if (!user?.seen_latest_features) {
                            // flip the seen_latest_features flag until the next release
                            const featuresResponse = await api.post(`/users/seen-latest-features`);
                            if (featuresResponse && featuresResponse.status !== 200) {
                                console.error('Error changing seen_latest_features');
                            }
                        }
                        setIsWhatsNewModalOpen(false);
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default WhatsNewModal;