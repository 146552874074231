import * as React from 'react';
import { useMemo } from 'react';
import { Box } from '@mui/system';
import Loading from '../ui/Loading';
import Grid from '@mui/material/Unstable_Grid2';
import CustomTooltip from '../ui/CustomTooltip';

const WordWallComponent = ({ labels, isLoading }) => {
  const primaryColor = '#0D49A0';
  // Memoize color palettes since they don't change
  const fullColorPaletteCategorical = useMemo(() => [
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#FA9625',
    '#E21F60',
    '#2E22DA',
    '#417C89',
    '#DA62BA',
    '#8100C1',
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#FA9625',
    '#E21F60',
    '#2E22DA',
    '#417C89',
    '#DA62BA',
    '#8100C1',
    '#226ff5',
    '#a788fc',
    '#35CA98',
    '#FA9625',
    '#E21F60',
    '#2E22DA',
    '#417C89',
    '#DA62BA',
    '#8100C1'
  ], []);
  const wordWidth = (index) => {
    // we want them to be divided into rows of 3, 5, 7, and 10
    if (index < 3) {
      return 'row-1';
    } else if (index >= 3 && index < 8) {
      return 'row-2';
    } else if (index >= 7 && index < 14) {
      return 'row-3';
    } else {
      return 'row-4';
    }
  }

  return (
    <Box className="word-wall-wrapper">
        <h3>Frequently Occurring Terms</h3>
        <Grid container className="word-wall">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {labels.map((label, index) => (
                <React.Fragment key={index}>
                  <CustomTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <p>{label.text}: {label.value}</p>
                      </React.Fragment>
                    }>
                    <Box
                      className={`word-wall-item ${wordWidth(index)}`}
                      sx={{ 'background': fullColorPaletteCategorical[index], 'color':'white' }}>
                      <Box className="word-wall-item-label">{label.text}</Box>
                    </Box>
                  </CustomTooltip>
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
    </Box>
  );
}

export default React.memo(WordWallComponent);
