import Box from '@material-ui/core/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const Admin = ({ user }) => {
    return (
        <Box className="dashboard">
            {user && (
                <Box className="admin dashboard-content">
                <Box>
                    <h1>Admin</h1>
                    <p>Crowdwave internal information</p>
                </Box>
                <Box className="admin-content">
                    <h3>User Information</h3>
                    <p><strong>Email:</strong>&nbsp;{user.email}</p>
                    <p><strong>User ID:</strong>&nbsp;{user.user_id}</p>
                    <h3>Feature Flags</h3>
                    {user.feature_flags && Object.keys(user.feature_flags).map((key) => {
                        let displayKey = key.toString().replace(/_/g, ' ');
                        if (key === 'model_splits') {
                            return (
                                <p key={key}><strong>{displayKey}:</strong>&nbsp;{JSON.stringify(user.feature_flags.model_splits)}</p>
                            );
                        } else {
                            return (
                                <p key={key}><strong>{displayKey}:</strong>&nbsp;{user.feature_flags[key].toString()}</p>
                            );
                        }
                    })}
                    <h3>User Limits:</h3>
                    {Object.keys(user).map((key) => {
                        let displayKey = key.toString().replace(/_/g, ' ');
                        if (key.includes('max')) {
                            return (
                                <p key={key}><strong>{displayKey}:</strong>&nbsp;{user[key]}</p>
                            );
                        }
                    })}
                </Box>
            </Box>
            )}
        </Box>
    );
};

export default Admin;